@font-face {
  font-family: 'Edenred';
  src: url("https://assets.eward.com.br/EdenredBold-Regular.woff2") format("woff2"), url("https://assets.eward.com.br/EdenredBold-Regular.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Edenred';
  src: url("https://assets.eward.com.br/EdenredBlack-Regular.woff2") format("woff2"), url("https://assets.eward.com.br/EdenredBlack-Regular.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat Alternates';
  src: url("https://assets.eward.com.br/MontserratAlternates-Regular.woff2") format("woff2"), url("https://assets.eward.com.br/MontserratAlternates-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat Alternates';
  src: url("https://assets.eward.com.br/MontserratAlternates-Thin.woff2") format("woff2"), url("https://assets.eward.com.br/MontserratAlternates-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat Alternates';
  src: url("https://assets.eward.com.br/MontserratAlternates-Bold.woff2") format("woff2"), url("https://assets.eward.com.br/MontserratAlternates-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Edenred';
  src: url("https://assets.eward.com.br/EdenredBold-Regular.woff2") format("woff2"), url("https://assets.eward.com.br/EdenredBold-Regular.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'EdenredRegular';
  src: url("https://assets.eward.com.br/edenred_light.woff") format("woff2"), url("https://assets.eward.com.br/edenred_light.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Edenred';
  src: url("https://assets.eward.com.br/EdenredBlack-Regular.woff2") format("woff2"), url("https://assets.eward.com.br/EdenredBlack-Regular.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat Alternates';
  src: url("https://assets.eward.com.br/MontserratAlternates-Regular.woff2") format("woff2"), url("https://assets.eward.com.br/MontserratAlternates-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat Alternates';
  src: url("https://assets.eward.com.br/MontserratAlternates-Thin.woff2") format("woff2"), url("https://assets.eward.com.br/MontserratAlternates-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat Alternates';
  src: url("https://assets.eward.com.br/MontserratAlternates-Bold.woff2") format("woff2"), url("https://assets.eward.com.br/MontserratAlternates-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'century gothic bold';
  src: url("https://assets.eward.com.br/GOTHICB.woff") format("woff2"), url("https://assets.eward.com.br/GOTHICB.woff") format("woff");
  font-weight: bold;
  font-style: normal; }


h1 {
  font-family: 'Edenred', sans-serif !important;
  font-weight: 400 !important; }
*, * > * {
  box-sizing: border-box; }

body {
  font-family: "century gothic bold", sans-serif;
  background: #fefefe;
  overflow-x: hidden; }



