.projects-component {
    width: 100%;
    .projects-h1-parent {
        background: #162056;
        padding: 15px;
        margin-bottom: 30px;
        h1 {
            text-align: left;
            color: white;
            font-size: 36px; }
        @media (min-width: 1200px) {
            padding: 10px 60px 10px 0;
            border-top-right-radius: 60px;
            border-bottom-right-radius: 60px; } }
    .projects-list {
        > li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ece9e9;
            font-size: 19.25px;
            color: #727176;
            > div > a {
                padding: 12px;
                display: flex;
                width: 100%;
                font-family: 'EdenredRegular' !important;
                cursor: pointer;
                text-decoration: none;
                color: #727176; }
            > div {
                cursor: pointer;
                &:first-child {
                    flex-grow: 1; }
                &:last-child {
                    .save-button {
                        transition: all 200ms;
                        &:hover {
                            color: #78be2d !important;
                            transform: scale(1.1); } }
                    min-width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px; } }
            &:hover {
                background: #f2f2f2; }
            &:last-child {
                border-bottom: none; } } } }



