.user-info-container {
  display: flex;
  font-family: 'Edenred';
  color: #fefefe;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4;
  position: absolute;
  bottom: 100px;
  right: 30px;
  img {
    padding-right: 15px;
    width: 60px;
    height: 60px; }
  @media (min-width: 1200px) {
    bottom: 40px;
    right: 15px; }
  .user-info-text {
    font-size: 18px;
    font-weight: bold; } }
