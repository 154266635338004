.header-container-parent {
  background: #162056;
  width: 100%;
  height: 235px;
  border-bottom: #DFE0E3 35px solid;
  @media(min-width: 1200px) {
    height: 275px;
    border-width: 75px; }
  position: relative;
  .header-container {
    height: 100%; }
  .logo-container-parent {
    top: 132px;
    bottom: 0;
    .logo-container {
      padding: 0 15px;
      img {
        display: block;
        margin: auto;
        width: 200px;
        @media(min-width: 1200px) {
          margin: unset; } } }
    background: #fff;
    padding: 15px;
    @media (min-width: 1200px) {
      padding: 20px 40px 20px 0;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px; } } }

