.criteria-component {
    flex-grow: 1;
    width: 100%;
    .row-ref {
        div {
            width: 100%; } }
    h1 {
        color: #162056;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @media (min-width: 1200px) {
            justify-content: left; } }
    label {
        font-size: 16px;
        margin-bottom: 15px;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid #bdbdbf;
        font-weight: bold;
        input {
          display: none; } }
    .active {
        color: #f2f2f2;
        background: #f72717; } }

.collapse-container {
    .reactReactCollapse--content div {
        padding: 0 0 15px 0 !important;
        font-size: 13px !important;
        line-height: 1.42 !important; } }

.ReactCollapse--collapse {
    transition: height 500ms; }
