.login-container {
  min-height: 200px;
  max-width: 400px;
  margin: auto;
  padding: 40px 15px 15px;
  h1 {
    color: #555;
    font-size: 21px;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center; } }
