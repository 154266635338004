.vote-component {
    font-family: "century gothic bold";
    button {
        margin-top: 30px;
        width: 80%;
        margin-bottom: 30px;
        font-size: 24px;
        border-radius: 50px;
        background: #00859b;
        color: #ffffff;
        border: none;
        font-weight: bold;
        cursor: pointer;
        padding: 10px;
        justify-content: center;
        align-items: center;
        display: flex;
        @media (max-width: 1200px) {
            margin-bottom: 30px !important; }
        &:disabled {
            background: grey;
            color: #fff;
            &:hover {
                color: #fff;
                background: grey; } }
        &:hover {
            background:  lighten(#00859b, 10%); } }

    .error {
        background: tomato !important; }
    .not-saved {
        color: #ffffff;
        background: tomato !important;
        &:hover {
            background: lighten(tomato, 5%) !important; }
        &:disabled {
            background: lighten(tomato, 10%) !important; } } }


.vote-component {
    .cols {
        @media(max-width: 1200px) {
            flex-direction: column-reverse; }
        div {
            @media(max-width: 1200px) {
                width: 100%; } } } }
