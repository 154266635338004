.react-tabs {
  -webkit-tap-highlight-color: transparent; }
.react-tabs__tab-list {
  border-bottom: 2px solid #f72717;
  margin: 0 0 30px;
  padding: 0 40px; }
.react-tabs__tab {
  display: inline-block;
  border: 2px solid transparent;
  border-bottom: none;
  bottom: -2px;
  position: relative;
  list-style: none;
  padding: 16px 32px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  color: #5b5b5f;
  font-family: "Montserrat Alternates";
  font-weight: 400;
  @media(max-width: 1200px) {
    padding: 10px 15px;
    font-size: 13px; }
  &:focus {
    outline: none;
    &:after {
      content: "";
      position: absolute;
      height: 5px;
      left: -4px;
      right: -4px;
      bottom: -5px;
      background: #fff; } } }
.react-tabs__tab--selected {
  background: #fff;
  border-color: #f72717;
  border-width: 2px;
  color: #000;
  border-radius: 15px 15px 0 0; }
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default; }
.react-tabs__tab-panel {
  display: none; }
.react-tabs__tab-panel--selected {
  display: block; }
