.project-component {
  font-family: 'Edenred';
  p {
    padding-left: 15px;
    color: #656565;
    font-family: 'century gothic bold';
    @media(min-width: 1200px) {
      padding: 0; } }
  .h1-left-verde-escuro-parent {
    background: #162056;
    padding: 15px;
    text-align: left;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0 10px 0;
    line-height: 1.62;
    .h1-left-verde-escuro {
      width: 100vw;
      float: none !important;
      @media (min-width: 1200px) {
        max-width: 510px;
        float: left; } }
    @media (min-width: 1200px) {
      padding: 5px 60px 5px 0;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px; } }

  .h1-right-verde-escuro-parent {
    background: #f72717;
    padding: 15px;
    text-align: left;
    color: white;
    font-size: 18px;
    line-height: 1.62;
    font-weight: bold;
    margin: 20px 0 10px 0;
    @media(max-width: 1200px) {
      right: 0 !important;
      float: left !important; }
    .h1-right-verde-escuro {
      width: 100vw;
      @media(max-width: 1200px) {
        margin-right: 0 !important; }
      @media (min-width: 1200px) {
        max-width: 510px; } }

    @media (min-width: 1200px) {
      padding: 5px 0 5px 60px;
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px; } }


  .main-h1 {
    .h1-azul {
      max-width: 1000px !important; } }
  .h1-verde-left-parent {
    background: #f72717;
    padding: 15px;
    text-align: left;
    color: #fff;
    font-size: 18px;
    line-height: 1.62;
    font-weight: bold;
    margin: 20px 0 10px 0;
    .h1-verde-left {
      width: 100vw;
      float: none !important;
      @media (min-width: 1200px) {
        max-width: 510px;
        float: left; } }
    @media (min-width: 1200px) {
      padding: 5px 60px 5px 0;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px; } }

  .h1-verde-right-parent {
    background: #f72717;
    padding: 15px;
    text-align: left;
    color: white;
    font-size: 18px;
    line-height: 1.62;
    font-weight: bold;
    margin: 20px 0 10px 0;
    @media(max-width: 1200px) {
      right: 0 !important;
      float: left !important; }
    .h1-verde-right {
      width: 100vw;
      @media(max-width: 1200px) {
        margin-right: 0 !important; }
      @media (min-width: 1200px) {
        max-width: 510px; } }

    @media (min-width: 1200px) {
      padding: 5px 0 5px 60px;
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px; } }


  .h1-azul-parent {
    background: #162056;
    padding: 15px;
    text-align: left;
    color: #fff;
    font-size: 18px;
    line-height: 1.62;
    font-weight: bold;
    margin: 20px 0 10px 0;
    .h1-azul {
      width: 100vw;
      max-width: 800px; }
    @media (min-width: 1200px) {
      font-size: 24px;
      padding: 5px 60px 5px 0;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px; } }

  .container-cinza-parent {
    background: #dfe0e3;
    padding: 15px;
    text-align: left;
    color: #000;
    font-size: 18px;
    line-height: 1.62;
    font-weight: bold;
    margin-bottom: 20px;
    .container-cinza {
      width: 100vw;
      max-width: 1200px; }
    @media (min-width: 1200px) {
      padding: 5px 0px 5px 0;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px; } } }

.project-inscr {
  .h1-azul {
    @media(min-width: 1200px) {
      text-align: right; } }
  .cols {
    > div {
      @media(min-width: 1200px) {
        width: 50% !important; } } } }

.cols {
  @media(min-width: 1200px) {
    display: flex; }
  > div {
    flex-grow: 1;
    @media(min-width: 1200px) {
      width: 50% !important; } } }

.cols {
  >div {
    width: 100%;
    &:first-child {
      @media(min-width: 1200px) {
        padding-right: 71px; } }
    &:last-child {
      @media(min-width: 1200px) {
        padding-left: 71px; } }
    @media(min-width: 1200px) {
      width: 50% !important; } } }

.project-team {

  .h1-azul-parent {
    margin-bottom: 20px;
    .h1-azul {
        max-width: 1200px !important; } }
  .cols > div {
    color: #727176;
    font-weight: 700;
    line-height: 21px; } }

.project-data {
  p {
    padding-left: 15px;
    @media(min-width: 1200px) {
      padding: 0; }
    color: #656565;
    font-size: 15px;
    line-height: 1.62;
    font-weight: 700; }
  h1 {
    font-size: 24px !important; }
  padding-bottom: 20px; }

.project-data-header {
  .h1-left-verde-escuro {
    @media (min-width: 1200px) {
      max-width: 250px !important; } } }

.cols button {
  width: 100%;
  margin-bottom: 30px;
  font-size: 24px;
  border-radius: 50px;
  background: #00859b;
  color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover {
    background: lighten(#00859b, 10%); } }

.project-actions {
  padding: 0 15px;
  margin-top: 50px; }

.skylight-dialog {
  @media(max-width: 1200px) {
    overflow-y: scroll !important; } }
